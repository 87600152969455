<template>
	<div class="row">
		<div class="col-12">
			<div class="card">
				<div class="card-header">
					<div class="d-flex justify-content-between align-items-center">
						<div class="">
							<label for="status_active" class="d-block-inline px-2">
								<input
									type="radio"
									v-model="statusFilter"
									name="status"
									value="active"
									id="status_active"
									/>
								Active
							</label>
							<label for="status_inactive" class="d-block-inline px-2">
								<input
									type="radio"
									v-model="statusFilter"
									name="status"
									value="inactive"
									id="status_inactive"
									/>
								Inactive
							</label>
						</div>
						<div class="">
							<download-button
								v-if="tableData.length"
								:downloading="downloadingReport"
								label="Download Report"
								@download="downloadReport()"
								></download-button>
						</div>
					</div>
				</div>
				<b-table
					striped
					hover
					selectable
					responsive
					show-empty
					:items="tableData"
					:fields="fields"
					:current-page="currentPage"
					:busy="loading"
					@row-clicked="viewDetails"
					>
					<template #table-busy>
						<div
							class="
                d-flex
                flex-column
                justify-content-center
                align-items-center
              "
							>
							<div class="spinner-border" role="status"></div>
							<p class="text-center mt-2"><strong>Loading...</strong></p>
						</div>
					</template>

					<template #empty>
						<p class="text-center text-secondary">No records available</p>
					</template>
					<template #cell(serialNumber)="data">
						<p>
							{{ data.index + 1 }}
						</p>
					</template>
					<template #cell(avatar)="data">
						<user-avatar
							:user="{
								fname: data?.item?.driver?.fname,
								lname: data?.item?.driver?.lname,
								avatar: data?.item?.driver?.avatar,
							}"
							size="sm"
							>
						</user-avatar>
					</template>
					<template #cell(name)="data">
						{{ `${data?.item?.driver?.fname} ${data?.item?.driver?.lname}` }}
					</template>

					<template #cell(email)="data">
						{{ data?.item?.driver?.email }}
					</template>

					<template #cell(phone)="data">
						{{ data?.item?.driver?.phone }}
					</template>

					<template #cell(date)="data">
						{{ data.item.created_at | date('MMM dd, yyyy') }}
					</template>

					<template #cell(vehicle)="data">
						<div v-if="data?.item?.vehicle">
							<div class="d-flex align-items-center">
								<p class="mr-2"
									><img
										src="@/assets/img/vehicleIcon.svg"
										class="avatar-img"
										alt="Bus"
										/></p>
								<div>
									<p class="mb-0 pb-0">
										<span class="mr-2">{{ data?.item?.vehicle?.brand }}</span
											><span class="mr-2">{{ data?.item?.vehicle?.name }}</span>
									</p>
									<p class="mt-0 pt-0">
										<router-link
											:to="`/fleet/${data?.item.vehicle.id}/vehicle-info`"
											>
											<span
												>{{ data?.item?.vehicle?.registration_number }}
											</span>
										</router-link>
									</p>
								</div>
							</div>
						</div>
						<span v-else class="text-muted">No vehicle assigned</span>
					</template>

					<template #cell(status)="data">
						<span
							:class="`badge p-2 ${
								data?.item?.driver?.active === '1'
									? 'badge-success'
									: 'badge-danger'
							}`"
							>
							{{ data?.item?.driver?.active === '1' ? 'Active' : 'Inactive' }}
						</span>
					</template>

					<template #cell(actions)="data">
						<div class="">
							<b-dropdown
								size="sm"
								right
								text="Right align"
								variant="link"
								class="position-relative text-center w-100"
								:toggle-class="'text-decoration-none'"
								:no-caret="true"
								>
								<template #button-content>
									<div class="w-100 text-center">
										<i class="fe fe-more-horizontal"></i>
									</div>
								</template>
								<b-dropdown-item @click.prevent.stop="viewDetails(data.item)">
									View driver Information
								</b-dropdown-item>
								<b-dropdown-item
									:class="[
										data?.item?.driver?.active == '0'
											? 'text-success'
											: 'text-danger',
									]"
									@click.prevent.stop="unAssignVehicle(data.item)"
									>
									{{
										data?.item?.driver?.active == '0' ? 'Unsuspend' : 'Suspend'
									}}
									driver
								</b-dropdown-item>
								<b-dropdown-item
									@click.prevent.stop="unAssignVehicle(data.item)"
									>
									Unlink driver
								</b-dropdown-item>
							</b-dropdown>
						</div>
					</template>
				</b-table>

				<div class="card-footer" v-if="totalRecords">
					<div class="row align-items-center">
						<div class="col">
							<span class="text-dark font-weight-bold">{{
								tablePageText
							}}</span>
						</div>
						<div class="col-auto">
							<b-pagination
								v-model="currentPage"
								:total-rows="totalRecords"
								:per-page="pageSize"
								></b-pagination>
						</div>
					</div>
				</div>
			</div>
		</div>

		<delete-prompt-modal
			modal-title="Are you absolutely sure ?"
			@close="showUnlinkVehicleModal = false"
			:verifying-delete="false"
			verification-message="It’s important you read the message below!"
			:processing-action="unlinkingVehicle"
			:show="showUnlinkVehicleModal"
			:show-prompt-key-label="true"
			prompt-text="Unlinking a driver from a vehicle partner will delete the driver’s record from the partner’s drivers list."
			prompt-key="KEY"
			:delete-function="
				() => {
					unlinkDriver()
				}
			"
			>
		</delete-prompt-modal>
	</div>
</template>

<script>
import tableView from '@/mixins/tableview'
import UserAvatar from '@/components/core/UserAvatar'
import DeletePromptModal from '@/components/modals/DeletePromptModal'
import { extractErrorMessage } from '@/utils/helpers'
import { ExportToCsv } from 'export-to-csv'
import DownloadButton from '@/components/core/DownloadButton.vue'

export default {
  name: 'PartnerDrivers',
  components: { UserAvatar, DeletePromptModal, DownloadButton },
  mixins: [tableView],
  props: {
    partnerId: {
      type: [Number, String],
      required: true
    }
  },
  data () {
    return {
      pageSize: 10,
      currentPage: 1,
      totalRecords: 0,
      loading: false,
      errorLoading: false,
      statusFilter: 'active',
      currentDriver: null,
      tableData: [],
      fields: [
        {
          key: 'serialNumber',
          label: 'S/N'
        },
        {
          key: 'avatar',
          label: 'Avatar'
        },
        {
          key: 'name',
          label: 'Name'
        },
        {
          key: 'email',
          label: 'Email'
        },
        {
          key: 'phone',
          label: 'Phone Number'
        },
        {
          key: 'vehicle',
          label: 'Vehicle'
        },
        {
          key: 'date',
          label: 'Date Added'
        },
        {
          key: 'status',
          label: 'Status'
        },
        {
          key: 'actions',
          label: 'Actions'
        }
      ],
      showAddVehicleModal: false,
      showUnlinkVehicleModal: false,
      unlinkingVehicle: false,
      downloadingReport: false,
      accountSid: this.$route.params.accountSid
    }
  },
  watch: {
    currentPage (value) {
      if (value) {
        this.fetchPartnerDrivers(false)
      }
    },
    statusFilter () {
      this.fetchPartnerDrivers(true)
    }
  },
  created () {
    this.fetchPartnerDrivers()
  },
  methods: {
    unAssignVehicle (data) {
      this.currentDriver = data
      this.showUnlinkVehicleModal = true
    },
    countDriverAssignedVehicles (routeVehicles) {
      let count = 0
      routeVehicles.forEach((data) => {
        if (data.vehicle) count += 1
      })
      return count
    },
    closeUnlinkModal () {
      this.currentDriver = null
      this.showUnlinkVehicleModal = false
    },
    unlinkDriver () {
      this.unlinkingVehicle = true
      // TODO: refactor with use of vehicle-partner.service class
      this.axios
        .patch(`/v1/drivers/${this.currentDriver.driver.id}`, {
          active: this.currentDriver.driver.active == '0' ? 1 : 0
        })
        .then((res) => {
          this.fetchPartnerDrivers(true)
          this.closeUnlinkModal()
          this.$swal({
            icon: 'success',
            title: `Driver ${
              res.data?.active === '0' ? 'Suspended' : 'Un-suspended'
            }`,
            text: `Driver has been ${
              res.data?.active === '0' ? 'suspended' : 'un-suspended'
            } successfully`,
            showCloseButton: true
          })
          this.unlinkingVehicle = false
        })
        .catch((error) => {
          this.unlinkingVehicle = false
          const msg = extractErrorMessage(
            error,
            'An error occurred, please try again.'
          )
          this.$swal().showValidationMessage(msg)
        })
    },

    fetchPartnerDrivers (showLoader = true) {
      if (showLoader) this.loading = true
      const params = {
        status: this.statusFilter,
        page: this.currentPage,
        limit: this.pageSize
      }

      this.axios
        .get(`/v1/partners/${this.$route?.params?.accountSid}/drivers`, {
          params
        })
        .then((res) => {

          this.tableData = res.data.data
          this.totalRecords = res.data.metadata?.total
        })
        .finally(() => {
          this.loading = false
        })
    },

    viewDetails (data) {
      this.$router.push({
        name: 'ShowDriver',
        params: { driverId: data.driver.id }
      })
    },
    downloadReport () {
      this.downloadingReport = true
      const csvData = this.tableData.map((data) => {
        const driver_vehicles = data.vehicles.map((eachVehicle) => {
          return eachVehicle.brand + ' ' + eachVehicle.name
        })
        return {
          name: data?.fname + ' ' + data?.lname,
          email: data?.email,
          phone: data?.phone,
          vehicle: driver_vehicles,
          date_joined: data?.created_at,
          status: data?.active === '1' ? 'Active' : 'Inactive'
        }
      })

      const csvParams = {
        fieldSeparator: ',',
        filename: 'Drivers List',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: 'Drivers List',
        useTextFile: false,
        useBom: true,
        headers: ['Name', 'Email', 'Phone', 'Vehicle', 'Date Joined', 'Status']
      }

      const csvExporter = new ExportToCsv(csvParams)

      csvExporter.generateCsv(csvData)
      this.downloadingReport = false
      this.$swal({
        icon: 'success',
        title: 'Report downloaded',
        text: 'Report has been downloaded successfully',
        showCloseButton: true
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/b-table';
</style>
